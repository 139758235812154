import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import InstagramIcon from '@material-ui/icons/Instagram'
// import MailIcon from '@material-ui/icons/Mail'
// import ResumeIcon from '@material-ui/icons/Resume'
import AssignmentIcon from '@material-ui/icons/Assignment';
import { about } from '../../portfolio'
import './About.css'

const About = () => {
  const { name, role, description, social } = about

  return (
    <div className='about center'>
      {name && (
        <h1>
          Hi, I am <span className='about__name'>{name}</span>
        </h1>
      )}

      {role && <h2 className='about__role'>{role}</h2>}
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>
        

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
              >
                <LinkedInIcon />
              </a>
            )}

            {social.instagram && (
              <a
                href={social.instagram}
                aria-label='instagram'
                className='link link--icon'
              >
                <InstagramIcon />
              </a>
            )}

            {/* {social.mail && (
              <a
                href={social.mail}
                aria-label='mail'
                className='link link--icon'
              >
                <MailIcon />
              </a>
            )}   */}

            {social.resume && (
              <a
                href={social.resume}
                aria-label='resume'
                className='link link--icon'
              >
                <AssignmentIcon />
              </a>
            )} 
          </>
        )}
      </div>
    </div>
  )
}

export default About
