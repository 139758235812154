const header = {
  title: 'Aravind',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Aravind Balakrishnan',
  role: 'Buffalo, NY | Graduate Student | Computer Science',
  description:
    'Hi! I am a Software Engineer with a passion for photography and cooking. I have a strong background in developing innovative and user-friendly applications',
  social: {
    resume: 'https://www.aravindbalakrishnan.com/ARAVIND_BALAKRISHNAN_RESUME.pdf',
    linkedin: 'https://linkedin.com/in/aravindbalakrishnan',
    github: 'https://github.com/AravBk',
    instagram: 'https://www.instagram.com/__amateur__lens__/',
  },
}

const projects = [
  // projects can be added an removed
  {
    name: 'Search Engine - Twitter',
    description:
      'Built a Search Engine for tweets related to COVID-19.',
    stack: ['Python', 'Flask', 'Solr', 'AngularJS'],
    sourceCode: 'https://github.com/AravBk/Twitter-Search-Engine',
    // livePreview: 'https://github.com',
  },
  {
    name: 'Publisher Subscriber System',
    description:
      'A Publisher Subrscriber model built using Docker.',
    stack: ['Docker', 'Kafka', 'Python', 'Flask'],
    sourceCode: 'https://github.com/AravBk/Publisher-Suscriber-System',
    // livePreview: 'https://github.com',
  },
  {
    name: 'Question and Answer Portal',
    description:
      'Built a DB model by extracting data from Stack Overflow',
    stack: ['GO' , 'PostgreSQL'],
    sourceCode: 'https://github.com',
    // livePreview: 'https://github.com',
  },
  {
    name: 'Text - Chat Application',
    description:
      'A client-server text-chat model that supports multi-client interaction through TCP.',
    stack: ['C'],
    sourceCode: 'https://github.com/AravBk/Text-Chat-Application',
    // livePreview: 'https://github.com',
  },
  {
    name: 'Handwritten Digits Detection',
    description:
      'Implemented a MultiLayer perceptron Neural Network to classify handwritten digits.',
    stack: ['Python'],
    sourceCode: 'https://github.com/AravBk/CSE-574-Machine-Learning/tree/main/PA2',
    // livePreview: 'https://github.com',
  },
  {
    name: 'Smart India Hackathon - 2019',
    description:
      'Automted the detection of chocking in Air Filters using Ardunio and RasberryPI.',
    stack: ['Python' , 'Flask' , 'Arduino' , 'RasberryPi'],
    sourceCode: 'https://github.com/AravBk/Smart-India-Hackathon',
    // livePreview: 'https://github.com',
  },
  {
    name: 'Intelligent Driver Safety System',
    description:
      'Detect eye closure, alert driver and notify close family to prevent accidents',
    stack: ['Python' , 'Arduino' , 'RasberryPi' , 'VibrationMotor', 'NodeMCU', 'Telegram'],
    sourceCode: 'https://github.com/AravBk/Intelligent-Driver-Safety-System',
    // livePreview: 'https://github.com',
  },
  // {
  //   name: 'Masters Project - Helpt',
  //   description:
  //     'iOS/Android Application to help people around your city.',
  //   stack: ['React','TypeScript'],
  //   // sourceCode: 'https://github.com',
  // },
  {
    name: 'University Symposium Website 2018',
    description:
      'The design and appearance of the website has been inspired from the linux terminal.',
    stack: ['HTML', 'CSS', 'PHP', 'Python'],
    sourceCode: 'https://github.com/AravBk/Interrupt18-Website',
    // livePreview: 'https://github.com',
  },
  {
    name: 'University Symposium Website 2019',
    description:
      'The implementation of the website has been inspired from the Snake Game we used to play.',
    stack: ['HTML', 'CSS', 'JS', 'Firebase'],
    sourceCode: 'https://github.com/AravBk/Interrupt2k19-Website',
    // livePreview: 'https://github.com',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Python',
  'Java',
  'JavaScript',
  'TypeScript',
  'React',
  'AngularJS',
  'NodeJS',
  'C',
  'C++',
  'Go',
  'HTML',
  'CSS',
  'Git',
  'Flask',
  'MySQL',
  'PostgreSQL',
  'MongoDB',
  'DJango',
  'Unix',
  'AWS',
  'Docker',
  'SDLC',
  'Agile',
  'Kafka',
  'Apache Solr',
  'Scrum',
  'Linux',
  'Arduino',
  'RasberryPi',
  'Postman',
  'TwitterAPI',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'aravind.trb@gmail.com',
}

// const experience = [
// {  
//   company : 'Ipserlab LLC',
//   role: 'Software Engineering Intern',
//   // url: 'https://www.ipserlab.com',
// },
// {
//   company : 'Qube Cinema',
//   role: 'Software Developer Intern',
//   // url: 'https://www.qubecinema.com',
// },
// ]

const accomplishments = [
  {
    title : 'Smart India Hackathon 2019',
  }
]
export { header, about, projects, skills, contact, accomplishments }
